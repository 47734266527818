<template>
    <div class="courseList">
        <div class="box">
            <div class="classBox">
                <div class="classContent">
                    <p class="label">案例分类：</p>
                    <div class="class1 class">
                        <p class="classItem" :class="{ classAcitve: calssIndex1 === index }"
                            @click="handleClass1(item, index)" v-for="(item, index) in class1"
                            :key="index + 'class1'">
                            {{ item.classTitle ||item.name }}
                        </p>
                    </div>
                </div>
                <div class="classContent" v-if="class2 && class2.length">
                     <P class="label">事故发生环节：</P>
                    <div class="class2 class">
                        <p class="classItem" :class="{ classAcitve: calssIndex2 === index }"
                            @click="handleClass2(item, index)" v-for="(item, index) in class2"
                            :key="index + 'class2'">
                            {{ item.classTitle ||item.name }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="listContainer">
                    <div class="left-list">
                        <div class="listBox">
                            <div class="listItem" v-for="item in AllCourseList" :key="item.id"
                                @click="handleDetail(item)">
                                <img :src="item.articleCover" class="cover" alt="" />
                                <div class="info">
                                    <p class="name">{{item.articleTitle }}</p>
                                    <p class="num">{{ item.articleAbstract }}</p>
                                </div>
                            </div>
                        </div>
                        <article v-if="noData" class="ListEmptyData">
                            <img src="../../../assets/images/course-icon.png" alt />
                            <p>没有找到任何案例</p>
                        </article>
                    </div>
                </div>
                <div class="pagination">
                    <p class="total">共{{ total }}个案例</p>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page.sync="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getConfigureValue } from '@/utils/configure.js'

export default {
    data() {
        return {
            noData: null,
            total: 0,
            pageNum: 1,
            pageSize: 12,
            calssIndex1: 0,
            calssIndex2: 0,
            class1: [{ name: "全部", id: "" }],
            class2: [],
            AllCourseList: [],
            currentClassCode: "",
            classArr:getConfigureValue('homeArticlesClassIDsAndNames') && JSON.parse(getConfigureValue('homeArticlesClassIDsAndNames')),

        };
    },
    created() {
        this.currentClassCode = this.classArr[3]?.ArticleClassID;
        // this.class1.push({
        //     name:this.classArr[3]?.ArticleName,
        //     id:this.classArr[3]?.ArticleClassID
        // })
        this.class1[0].id = this.classArr[3]?.ArticleClassID;
        this.getCourseList();
        this.getClassifyFindAll(this.currentClassCode);
    },
    methods: {
        filterTreeData(data) {
            return data.map((item) => {
                if (item.children) {
                    item.children = this.filterTreeData(item.children);
                }
                return item;
            });
        },
        handleClass1(item, index) {
            this.calssIndex1 = index;
            this.calssIndex2 = 0;
            this.class2 = [];
            if(item.children && item.children.length){
                this.class2 = [{
                    name: "全部",
                    id: item.id,
                },...item.children];
            }
            this.currentClassCode = item.id;
            this.pageNum = 1;
            this.getClassifyFindAll(this.currentClassCode);
            this.getCourseList();
        },
        handleClass2(item, index) {
            this.calssIndex2 = index;
            this.currentClassCode = item.id;
            this.pageNum = 1;
            this.getCourseList();
        },
        // 获取所有分类
        getClassifyFindAll(id) {
            this.$api.ema.getTreeByDomain({
                classId:id
            }).then((res) => {
                if (res.data && res.data.length) {
                    let arr1 = [{
                        name: "全部",
                        id: this.classArr[3]?.ArticleClassID,
                    }];
                    arr1 = this.filterTreeData(res.data);
                    this.class1 = [{
                        name: "全部",
                        id: this.classArr[3]?.ArticleClassID,
                    },...arr1] ;
                }
            });
        },
        getCourseList() {
            const params = {
                classId: this.currentClassCode,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                sort:1,
            };
            this.$api.ema.getArticlesByDomain(params).then((res) => {
                if (res.data) {
                    if (res.data.list && res.data.list.length) {
                        this.noData = false;
                    } else {
                        this.noData = true;
                    }
                    this.AllCourseList = res.data.list || [];
                    this.total = res.data.total;
                }
            });
        },
        handleDetail(item) {
           this.$router.push({
                path: "/cms/detail",
                query: {
                    articleId: item.id,
                },
            });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getCourseList();
        }
    },
};
</script>

<style lang="scss" src="../css/list.scss" scoped></style>
<style lang="scss" scoped>
.configTile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #1B2257;
    line-height: 28px;
    margin-top: 32px padding-left 12px position relative height 28px;
    margin-bottom: 16px;

    font {
        position: relative;
        padding-left: 12px;
    }

    font:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 18px;
        background: #E30000;
        left: 0;
        top: 50%;
        transform: translateY(-50%)
    }
}
</style>